#header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background: rgba(40, 58, 90, 0.9);
  border: none;
  z-index: 1;
  transition: all 0.5s ease;
}
